import { Routes, Route, useLocation } from 'react-router-dom'
import { useContext, useEffect, useState } from 'react'
import { Container, Modal, SiteLayout, Footer } from '@jsluna/react'

import { AppContext } from './context/AppContext'

import { InteractionType } from '@azure/msal-browser'

import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsalAuthentication
} from '@azure/msal-react'

import './scss/main.scss'
import { useCurrentStore, useStoreLoader } from './hooks'
import NavHeader from './components/Header/NavHeader'
import StorePage from './pages/StorePage'

import GoogleAnalytics from './components/GoogleAnalytics'
import HomePage from './pages/home'
import { IStore } from './types/IStore'
import Loading from './components/Loading'

const App = () => {
  const context = useContext(AppContext)

  useMsalAuthentication(InteractionType.Redirect, {
    scopes: [context?.apiScope ?? '']
  })

  const location = useLocation()

  useEffect(() => {
    const storedState = sessionStorage.getItem('AppContextData')
    if (storedState && context) {
      context.appContextData = JSON.parse(storedState)
    } else {
      setStore(context!.appContextData.store!)
    }
  }, [location])

  const currentStore = useCurrentStore()

  if (currentStore?.storeCode != '' && !context?.appContextData.store) {
    context!.appContextData.store = currentStore
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [store, setStore] = useState(currentStore)
  const [storeResource, loadStore] = useStoreLoader()

  useEffect(() => {
    loadStore.current(currentStore.storeCode)
  }, [context, loadStore])

  if (
    context &&
    storeResource.data &&
    context.appContextData.store === undefined
  ) {
    const storeDetails = storeResource.data
    const store: IStore = {
      storeCode: storeDetails.code,
      storeName: storeDetails.name,
      inScope: storeDetails ? true : false
    }
    context.appContextData.store = store
    setStore(store)
  }

  if (!storeResource.hasLoaded && !storeResource.hasError) return <Loading />

  if (
    (context &&
      context.appContextData.store !== undefined &&
      !context.appContextData.store.inScope) ||
    (storeResource.hasError &&
      context?.appContextData.store?.storeCode === undefined)
  ) {
    return (
      <AuthenticatedTemplate>
        <Routes>
          <Route path="*" element={<StorePage />} />
        </Routes>
      </AuthenticatedTemplate>
    )
  }

  if (currentStore.storeCode === '') return <Loading />

  return (
    <>
      <AuthenticatedTemplate>
        <SiteLayout>
          <NavHeader />
          <Container size="md" soft>
            <main id="main-content" className="ln-u-push-top-md">
              <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/stores" element={<StorePage />} />
              </Routes>
            </main>
            <GoogleAnalytics />
          </Container>
          <Footer>&copy; Sainsbury&apos;s Supermarkets Ltd</Footer>
        </SiteLayout>
      </AuthenticatedTemplate>

      <UnauthenticatedTemplate>
        <Modal restrictClose open={true}>
          <h4> Redirecting.. </h4>
        </Modal>
      </UnauthenticatedTemplate>
    </>
  )
}

export default App
