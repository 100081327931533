import React from 'react'
import ReactGA from 'react-ga'

const gtag = 'UA-77515350-22'
class GoogleAnalytics extends React.Component {
  constructor() {
    super()
    ReactGA.initialize(gtag)
    this.logEvent = () => {
      const curUrl = window.location.pathname + window.location.search
      if (this.currentLink !== curUrl) {
        this.currentLink = curUrl
        ReactGA.pageview(curUrl)
      }
    }
  }

  componentDidMount() {
    this.logEvent()
  }

  componentDidUpdate() {
    this.logEvent()
  }

  render() {
    return null
  }
}
export default GoogleAnalytics
