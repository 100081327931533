import { InfoCircle } from '@jsluna/icons'
import { Button } from '@jsluna/button'
import { PropTypes } from 'prop-types'
import { resetFilter } from '../../../utils/filtering'

const NoFilters = ({
  setCheckedItems,
  changeFilteredDevices,
  setIsSorted,
  handleNoFilterState,
}) => (
  <div className="dm__message-body">
    <h1 className="ln-u-text-align-center">
      <InfoCircle className="dm__message-icon" size="large" />
    </h1>
    <div className="ln-u-padding-sides*2">
      <h5 className="ln-u-text-align-center">
        There&apos;s nothing to see here
      </h5>
      <p>There&apos;s no devices which match your filter.</p>
      <p>
        Please refilter in order to find the device you&apos;re looking for.
      </p>
      <Button
        variant="filled"
        fullWidth
        onClick={() =>
          resetFilter(
            setCheckedItems,
            changeFilteredDevices,
            setIsSorted,
            handleNoFilterState,
          )
        }
      >
        Reset filter
      </Button>
    </div>
  </div>
)

NoFilters.propTypes = {
  setCheckedItems: PropTypes.func,
  handleNoFilterState: PropTypes.func,
  setIsSorted: PropTypes.func,
  changeFilteredDevices: PropTypes.func,
}

NoFilters.defaultProps = {
  setCheckedItems: () => { },
  handleNoFilterState: () => { },
  setIsSorted: () => { },
  changeFilteredDevices: () => { },
}

export default NoFilters
