import { useCurrentStore } from "../../../hooks"

const NavStoreName = () => {
  const store = useCurrentStore()
  
  if (store) {
    const storeCode = String(store?.storeCode).replace(/^[a-z]/i, '')

    const storeText =
    !store?.storeName || store?.storeName === ''
        ? `Store ${storeCode}`
        : `${store?.storeName} (${storeCode})`
    
    return <span className="cc-nav-fix">{storeText}</span>
  } else {
    return <></>
  }
}

export default NavStoreName
