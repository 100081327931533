import PropTypes from 'prop-types'
import getRelativeDate from '../../../utils/relativeDate'

function DevicesTableRow({ device }) {
  const { onlineStatus } = device
  return (
    <>
      <tr
        className={
          !onlineStatus
            ? 'ln-c-table__row dm__offlineDevice'
            : 'ln-c-table__row dm__onlineDevice'
        }
        key={device.deviceInfo.serialNumber}
        id={`device-row-${device.deviceInfo.serialNumber}`}
      >
        <td className="ln-c-table__cell dm__deviceModel" id="device-model">
          {device.deviceGroupName.includes('SmartShop')
            ? device.deviceGroupName
            : device.deviceInfo.simpleModel}
        </td>
        <td
          className="ln-c-table__cell dm__deviceFriendlyName"
          id="device-friendly-name"
        >
          {device.deviceInfo.deviceFriendlyName}
        </td>
        <td
          className="ln-c-table__cell dm__deviceSerialNumber"
          id="device-serial-number"
        >
          <span className="dm__mobileTag" id="mobile-tag">
            Serial Number &nbsp;
          </span>
          {device.deviceInfo.serialNumber}
        </td>
        <td
          className="ln-c-table__cell dm__deviceLastCheckIn"
          id="device-last-check-in"
        >
          <span className="dm__mobileTag" id="mobile-tag">
            Last Checked In &nbsp;
          </span>
          <span className={!onlineStatus ? 'red-text' : ''}>
            {getRelativeDate(device.lastSeen)}
          </span>
        </td>
      </tr>
    </>
  )
}

DevicesTableRow.propTypes = {
  device: PropTypes.shape({
    lastSeen: PropTypes.string.isRequired,
    onlineStatus: PropTypes.bool.isRequired,
    deviceGroupName: PropTypes.string.isRequired,
    deviceInfo: PropTypes.shape({
      deviceFriendlyName: PropTypes.string,
      simpleModel: PropTypes.string.isRequired,
      serialNumber: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
}

export default DevicesTableRow
