import IResourceLoader from '../types/IResourceLoader'
import { AppContext } from '../context/AppContext'
import { useApiClient } from '../context/AppContext'
import { useResourceLoader, useCallbackRef } from '.'
import { FetchDevices } from '../types/FetchDevices'
import { ILoadDevices } from '../types/ILoadDevices'
import { IDevice } from '../types/IDevices'
import devicesApi from '../api/DevicesApi'
import { useContext } from 'react'

const useDeviceLoader = (): [IResourceLoader<IDevice[]>, ILoadDevices] => {
  const context = useContext(AppContext)
  const apiClient = useApiClient()
  const resource = useResourceLoader<IDevice[]>('Unable to load device')

  const loader = useCallbackRef<FetchDevices>((storeCode: string) =>
    resource.load(async () => await devicesApi.getDevices(apiClient, context!, storeCode))
  )

  return [resource, loader]
}

export default useDeviceLoader
