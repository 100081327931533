import { ArrowUpDown } from '@jsluna/icons'
import { PropTypes } from 'prop-types'

const DevicesTableHead = ({ handleSort }) => (
  <thead className="ln-c-table__header" id="device-table-header">
    <tr className="ln-c-table__row ln-c-table__header-row">
      <th className="ln-c-table__header-cell customHead ln-c-table__header-cell--sortable">
        <button
          type="button"
          className="ln-c-table__sort-button ln-c-table__text"
          id="device-table-header-type"
        >
          Device Type{' '}
          <ArrowUpDown
            id="device-type-sort"
            onClick={event => handleSort(event)}
          />
        </button>
      </th>
      <th className="ln-c-table__header-cell customHead ln-c-table__header-cell--sortable">
        <button
          type="button"
          className="ln-c-table__sort-button ln-c-table__text"
          id="device-table-header-friendly-name"
        >
          Friendly Name{' '}
          <ArrowUpDown
            id="friendly-name-sort"
            onClick={event => handleSort(event)}
          />
        </button>
      </th>
      <th className="ln-c-table__header-cell customHead ln-c-table__header-cell--sortable">
        <button
          type="button"
          className="ln-c-table__sort-button ln-c-table__text"
          id="device-table-header-serial-number"
        >
          Serial Number{' '}
          <ArrowUpDown
            id="serial-number-sort"
            onClick={event => handleSort(event)}
          />
        </button>
      </th>
      <th className="ln-c-table__header-cell customHead ln-c-table__header-cell--sortable">
        <button
          type="button"
          className="ln-c-table__sort-button ln-c-table__text"
          id="device-table-header-last-check-in"
        >
          Last Check-in{' '}
          <ArrowUpDown
            id="last-seen-sort"
            onClick={event => handleSort(event)}
          />
        </button>
      </th>
    </tr>
  </thead>
)

DevicesTableHead.propTypes = {
  handleSort: PropTypes.func.isRequired,
}

export default DevicesTableHead
