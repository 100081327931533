import { AppContextType } from '../context/AppContext'
import { ApiClient } from './ApiClient'
import { IDevice } from '../types/IDevices'
import webApi from './webApi'

const getDevices = async (
  apiClient: ApiClient,
  context: AppContextType,
  code: string
): Promise<IDevice[]> => {

  const storeCode =
    code !== ''
      ? code
      : context.appContextData.store !== undefined
      ? context.appContextData.store!.storeCode
      : ''

  const response = await webApi.get<IDevice[]>(apiClient, {
    url: '/api/devices',
    params: { storeCode: storeCode, organisation: 'sainsburys' }
  })
  return response
}

const devicesApi = {
  getDevices
}

export default devicesApi
