import { useState } from "react"
import { PropTypes } from 'prop-types'
import sortData from "../../utils/sorting"
import DeviceCounter from "../DeviceCounter"
import { Button } from '@jsluna/button'
import DevicesTable from "./DevicesTable"
import NoFilters from "../Filters/NoFilters"
import FilterHeader from "../Filters/FilterHeader"


const DevicesInfoPanel = ({ ...devicesProps }) => {

  const [sortOrder, setSortOrder] = useState('asc')
  const [deviceData, setDeviceData] = useState([])

  const handleSort = event => {
    const newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc'
    setSortOrder(newSortOrder)
    const sortedData = sortData(
      event,
      sortOrder,
      devicesProps.filteredDevices,
      devicesProps.devices,
    )
    setDeviceData(sortedData)
    devicesProps.setIsSorted(true)
  }

  return (
    <>
      { (
        <div id="dm-dashboard" className="dm__dashboard">
          { (
            <>
              <DeviceCounter devices={devicesProps.devices} />
              <div className="dm__pills">
                {devicesProps.filteredDevices.length > 0 ? (
                  <FilterHeader
                    checkedItems={devicesProps.checkedItems}
                    setCheckedItems={devicesProps.setCheckedItems}
                    filteredDevices={devicesProps.filteredDevices}
                    filteredPills={devicesProps.filteredPills}
                    changeFilteredPills={devicesProps.changeFilteredPills}
                    changeFilteredDevices={devicesProps.changeFilteredDevices}
                    devices={devicesProps.devices}
                    setIsSorted={devicesProps.setIsSorted}
                    handleNoFilterState={devicesProps.handleNoFilterState}
                  />
                ) : null}
                <div
                  className="dm__filter-button-container"
                  id="filter-button-container"
                >
                  {devicesProps.devices.length > 0 && (
                    <Button
                      className="dm__filter-button dm__pills"
                      id="filter-button"
                      onClick={() => devicesProps.handleModal()}
                    >
                      All filters
                    </Button>
                  )}
                </div>
              </div>
              {devicesProps.NoFilterState === true ? (
                <div className="dm__main" id="no-filter-message">
                  <NoFilters
                    setCheckedItems={devicesProps.setCheckedItems}
                    changeFilteredDevices={devicesProps.changeFilteredDevices}
                    setIsSorted={devicesProps.setIsSorted}
                    handleNoFilterState={devicesProps.handleNoFilterState}
                  />
                </div>
              ) : Object.keys(devicesProps.devices).length > 0 ? (
                <div className="dm__main" id="devices-table">
                  <table
                    className="ln-c-table ln-c-table--responsive@md ln-c-table--sorted"
                    style={{ width: '100%' }}
                  >
                    <DevicesTable
                      devices={devicesProps.devices}
                      filteredDevices={devicesProps.filteredDevices}
                      handleSort={handleSort}
                      isSorted={devicesProps.isSorted}
                      deviceData={deviceData}
                    />
                  </table>
                </div>
              ) : (
                (!devicesProps.devices || devicesProps.devices.length===0) && (
                  <div className="dm__noneFound" id="device-noneFound">
                    Whoops! No Devices Were Allocated To This Store
                  </div>
                )
              )}
            </>
          )}
        </div>
      )}
    </>
  )
}

DevicesInfoPanel.propTypes = {
  devices: PropTypes.array, 
  isRequesting: PropTypes.bool,
  error: PropTypes.string,
  filteredDevices: PropTypes.array, // eslint-disable-line
  handleModal: PropTypes.func,
  checkedItems: PropTypes.array, // eslint-disable-line
  setCheckedItems: PropTypes.func,
  filteredPills: PropTypes.array, // eslint-disable-line
  changeFilteredPills: PropTypes.func,
  changeFilteredDevices: PropTypes.func,
  isSorted: PropTypes.bool.isRequired,
  setIsSorted: PropTypes.func,
  handleNoFilterState: PropTypes.func,
  NoFilterState: PropTypes.bool.isRequired,
}

DevicesInfoPanel.defaultProps = {
  devices: [],
  isRequesting: false,
  error: undefined,
  filteredDevices: [],
  handleModal: () => {},
  checkedItems: [],
  setCheckedItems: () => {},
  filteredPills: [],
  changeFilteredPills: () => {},
  changeFilteredDevices: () => {},
  setIsSorted: () => {},
  handleNoFilterState: () => {},
}

export default DevicesInfoPanel
