import { Button } from '@jsluna/button'
import { PropTypes } from 'prop-types'
import { Cancel } from '@jsluna/icons'
import { applyFilter, resetFilter } from '../../../utils/filtering'

const FilterHeader = ({
  checkedItems,
  setCheckedItems,
  filteredPills,
  changeFilteredPills,
  changeFilteredDevices,
  devices,
  setIsSorted,
  handleNoFilterState,
}) => {
  const maxPillDisplayForMobile = 1
  const maxPillDisplayForDesktop = 3

  const removeFilter = item => {
    const newFilteredPills = filteredPills.filter(
      filteredPill => filteredPill !== item,
    )

    changeFilteredPills(newFilteredPills)
    setCheckedItems(checkedItems.filter(checkedItem => checkedItem !== item))
    const filteredData = applyFilter(
      devices,
      newFilteredPills,
      handleNoFilterState,
    )
    changeFilteredDevices(filteredData)
  }

  const pillsName = item => {
    let result = item
    if (item === 'true') {
      result = 'Online (checked in today)'
    } else if (item === 'false') {
      result = 'Offline (not checked in)'
    }
    return result
  }

  const pillsForMobile = (
    <Button
      onClick={() => removeFilter(filteredPills[0])}
      className="filter-pill filter-pill--item dm__pills filter-pill--toggle"
      id="dm__filter-pill--item-mobile"
      type="button"
    >
      {pillsName(filteredPills[0])} <Cancel />
    </Button>
  )

  const overFlowForMobile = (
    <Button
      onClick={() =>
        resetFilter(
          setCheckedItems,
          changeFilteredDevices,
          setIsSorted,
          handleNoFilterState,
        )
      }
      className="filter-pill filter-pill--item dm__pills"
      id="dm__filter-pill--overflow-mobile"
      type="button"
    >
      {`${filteredPills.length} filters`} <Cancel />
    </Button>
  )

  const pillsForDesktop = filteredPills
    .slice(0, maxPillDisplayForDesktop)
    .map(item => (
      <Button
        key={`pill-${item}`}
        onClick={() => removeFilter(item)}
        className="filter-pill filter-pill--item  ln-u-margin-right"
        id="dm__filter-pill--item-desktop"
        type="button"
      >
        {pillsName(item)} <Cancel />
      </Button>
    ))

  const overFlowForDesktop = (
    <Button
      key="pill-overflow"
      className="filter-pill filter-pill--item"
      type="button"
    >
      <span
        className="ln-u-padding-right*1/2"
        id="dm__filter-pill--overflow-desktop"
      >
        {`${filteredPills.length - maxPillDisplayForDesktop} other`}
      </span>
    </Button>
  )

  if (filteredPills.length > 0) {
    return (
      <div className="dm__filter-header">
        <div className="dm__filter-pill-desktop" id="dm__filter-pill-desktop">
          {filteredPills.length <= maxPillDisplayForDesktop
            ? pillsForDesktop
            : [pillsForDesktop, overFlowForDesktop]}
        </div>
        <div className="dm__filter-pill-mobile" id="dm__filter-pill-mobile">
          {filteredPills.length === maxPillDisplayForMobile
            ? pillsForMobile
            : overFlowForMobile}
        </div>
      </div>
    )
  }
  return null
}

FilterHeader.propTypes = {
  devices: PropTypes.array, // eslint-disable-line
  checkedItems: PropTypes.array, // eslint-disable-line
  setCheckedItems: PropTypes.func,
  filteredPills: PropTypes.array, // eslint-disable-line
  changeFilteredPills: PropTypes.func,
  changeFilteredDevices: PropTypes.func,
  handleNoFilterState: PropTypes.func,
  setIsSorted: PropTypes.func,
}

FilterHeader.defaultProps = {
  devices: [],
  checkedItems: [],
  filteredPills: [],
  setCheckedItems: () => { },
  changeFilteredPills: () => { },
  changeFilteredDevices: () => { },
  handleNoFilterState: () => { },
  setIsSorted: () => { },
}

export default FilterHeader
