import { FC, useContext, useEffect } from 'react'
import Dashboard from '../components/Dashboard'
import { AppContext } from '../context/AppContext'
import { useDeviceLoader } from '../hooks'
import useCurrentStoreDevices from '../hooks/useCurrentStoreDevices'
import Loading from '../components/Loading'

interface HomePageProps {}

const HomePage: FC<HomePageProps> = () => {
  const context = useContext(AppContext)

  const [resource, loader] = useDeviceLoader()

  useEffect(() => {
    loader.current(context!.appContextData.store!.storeCode)
  }, [context!.appContextData.store, loader])

  if(context && resource.hasError){
    context.appContextData.devices = undefined
  }

  if (context && resource.hasLoaded) {
    context.appContextData.devices = resource.data
  }

  const devices = useCurrentStoreDevices()

  if (!resource.hasLoaded && !resource.hasError)
    {return <Loading message={'Loading Devices'} />}

  if(resource.hasError)
  {return <Dashboard />}

  return <Dashboard devices={devices} />
}

export default HomePage
