export const applyFilter = (devices, devicesData, handleNoFilterState) => {
  const uniqueDevices = devices.filter(
    (obj, pos, arr) =>
      arr
        .map(device => device.deviceInfo.simpleModel)
        .indexOf(obj.deviceInfo.simpleModel) === pos,
  )

  const simpleModelList = uniqueDevices.map(
    device => device.deviceInfo.simpleModel,
  )

  const pillFilterAnd = devices.filter(
    device =>
      devicesData.includes(device.deviceInfo.simpleModel) &&
      devicesData.includes(device.onlineStatus.toString()),
  )

  const pillFilterOr = devices.filter(
    device =>
      devicesData.includes(device.deviceInfo.simpleModel) ||
      devicesData.includes(device.onlineStatus.toString()),
  )

  const filteredData =
    devicesData.some(c => simpleModelList.includes(c)) &&
    devicesData.some(c => ['true', 'false'].includes(c))
      ? pillFilterAnd
      : pillFilterOr

  if (devicesData.length > 0) {
    handleNoFilterState(!(filteredData.length > 0))
  } else {
    handleNoFilterState(false)
  }
  return filteredData
}

export const resetFilter = (
  setCheckedItems,
  changeFilteredDevices,
  setIsSorted,
  handleNoFilterState,
) => {
  setCheckedItems([])
  changeFilteredDevices({})
  setIsSorted(false)
  handleNoFilterState(false)
}

export default {
  applyFilter,
  resetFilter,
}
