import { useContext, useEffect, useState } from 'react'
import { AppContext } from '../context/AppContext'
import { IDevice } from '../types/IDevices'

const useCurrentStoreDevices = (): IDevice[] => {
  const context = useContext(AppContext)

  const [devices, setDevices] = useState(
    context && context.appContextData.devices !== undefined ? context.appContextData.devices : []
  )

  useEffect(() => {
    if (context && context.appContextData.devices !== undefined) {
      setDevices(context.appContextData.devices)
    }
  }, [context!.appContextData.devices])

  return devices
}

export default useCurrentStoreDevices
