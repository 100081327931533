import { useState } from 'react'
import DevicesInfoPanel from '../DevicesTable'
import FilterPanel from '../Filters/FilterPanel'
import { PropTypes } from 'prop-types'
import { Container } from '@jsluna/grid'
import { useCurrentStore } from '../../hooks'

const Dashboard =  ({ devices }) => {
  let store = useCurrentStore()

  const [filteredDevices, setFilteredDevices] = useState([])
  const [checkedItems, setCheckedItems] = useState([])
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [filteredPills, setFilteredPills] = useState([])
  const [isSorted, setIsSorted] = useState(false)
  const [noFilterState, setNoFilterState] = useState(false)

  const changeFilteredDevices = newFilter => {
    setFilteredDevices(newFilter)
    setIsSorted(false)
  }

  const handleModal = () =>
    setIsModalOpen(!isModalOpen) 

  const changeFilteredPills = newFilteredPills => {
    setFilteredPills(newFilteredPills)
  }

  const handleNoFilterState = newNoFilterState => {
    setNoFilterState(newNoFilterState)
  }

  return (
    <Container className="dashboard-container ln-u-flush-left">
      <div className="dm__filter-panel-wrapper ln-u-grid">
        <FilterPanel
          devices={devices}
          changeFilteredDevices={changeFilteredDevices}
          handleModal={handleModal}
          isModalOpen={isModalOpen}
          checkedItems={checkedItems}
          setCheckedItems={setCheckedItems}
          changeFilteredPills={changeFilteredPills}
          setIsSorted={setIsSorted}
          handleNoFilterState={handleNoFilterState}
        />
      </div>
      <div className="dashboard__grid" id="dashboard-card">
        <h3 className="dm__title" id="device-title">
          Devices for {store.storeName} ({store.storeCode})
        </h3>
        <DevicesInfoPanel
          devices={devices}
          filteredDevices={filteredDevices}
          handleModal={handleModal}
          checkedItems={checkedItems}
          setCheckedItems={setCheckedItems}
          filteredPills={filteredPills}
          changeFilteredPills={changeFilteredPills}
          changeFilteredDevices={changeFilteredDevices}
          isSorted={isSorted}
          setIsSorted={setIsSorted}
          handleNoFilterState={handleNoFilterState}
          NoFilterState={noFilterState}
        />
      </div>
    </Container>
  )
}
Dashboard.propTypes = {
   devices: PropTypes.instanceOf(Array)
 }
 

Dashboard.defaultProps = {
  devices: []
}

export default Dashboard
