import { isYesterday, isToday, formatDistanceStrict } from 'date-fns'

const getRelativeDate = date => {
  const currentDate = new Date()
  const inputDate = new Date(date)

  return isToday(inputDate, currentDate)
    ? 'Today'
    : isYesterday(inputDate)
    ? 'Yesterday'
    : `${formatDistanceStrict(inputDate, currentDate, { unit: 'day' })} ago`
}

export default getRelativeDate
