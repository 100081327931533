import { NavLink } from 'react-router-dom'
import { Display3, Caption } from '@jsluna/typography'
import {
  Header as LunaHeader,
  HeaderLogo,
  HeaderNav,
  HeaderMainBar,
  HeaderGlobalItem
} from '@jsluna/header'
import HeaderGlobalBar from './CustomHeaderGlobalBar'
import {
  ChangeStore,
  NavCurrentUser,
  NavStoreName,
  SainsburysLogo
} from './nav'
import SignOutButton from '../auth/SignOutButton'

const NavHeader = () => {
  return (
    <LunaHeader divided hasGlobalBar hasMainBar>
      <HeaderGlobalBar
        label="Group navigation"
        element="nav"
        className={undefined}
        size={undefined}
      >
        <HeaderGlobalItem>
          <SainsburysLogo />
        </HeaderGlobalItem>
        <HeaderGlobalItem align="right" drawer="true" tabBar="md">
          <span id="device-user-store">
            <NavCurrentUser />&nbsp;in&nbsp;<NavStoreName />
          </span>
        </HeaderGlobalItem>
        <HeaderGlobalItem>
          <span id="device-nav-link-stores">
            <ChangeStore />
          </span>
        </HeaderGlobalItem>
        <HeaderGlobalItem>
          <span id="device-nav-link-signOut">
            <SignOutButton />
          </span>
        </HeaderGlobalItem>
      </HeaderGlobalBar>

      <HeaderMainBar size="nav">
        <HeaderLogo>
          <Display3 id="device-logo">
            <NavLink to="/" style={{ textDecoration: 'none' }}>
              Store Devices
            </NavLink>
          </Display3>
        </HeaderLogo>
        <HeaderNav align="right" drawer className="a-u-hidden@max-nav">
          <Caption>
            <NavCurrentUser />&nbsp;in&nbsp;<NavStoreName />
          </Caption>
          <Caption>
            {' '}
            <ChangeStore />{' '}
          </Caption>
          <Caption>
            {' '}
            <SignOutButton />
          </Caption>
        </HeaderNav>
      </HeaderMainBar>
    </LunaHeader>
  )
}

export default NavHeader
