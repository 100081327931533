/* eslint-disable react/prop-types */
import {
  ReactNode,
  createContext,
  useContext,
} from 'react'
import { useMsal } from '@azure/msal-react'
import { ApiClient } from '../api/ApiClient'
import { IStore } from '../types/IStore'
import { IDevice } from '../types/IDevices'
import { IDetails } from '../types/IStoreDetails'

export interface AppContextData {
  store?: IStore | undefined
  devices?: IDevice[] | undefined
}

export type AppContextType = {
  apiClient: ApiClient
  apiScope: string
  storeList?: IDetails[] | undefined
  appContextData: AppContextData
  setData: (data: any, value: any) => void
}

export const AppContext = createContext<AppContextType | null>(null)

type AppProviderProps = {
  apiScope: string
  apiBaseUrl: string
  store?: IStore | undefined
  storeList?: IDetails[] | undefined
  devices?: IDevice[] | undefined
  children?: ReactNode
}

export const AppProvider: React.FC<AppProviderProps> = ({
  apiScope,
  apiBaseUrl,
  store,
  devices,
  children
}) => {
  const { instance } = useMsal()
  const apiClient = new ApiClient(instance, apiScope, apiBaseUrl)

  const appContextData: AppContextData = {
    store,
    devices
  }

  const setData = (property: keyof AppContextData, value: any) => {
    if (Object.prototype.hasOwnProperty.call(appContextData, property)) {
        appContextData[property] = value;
        sessionStorage.setItem('AppContextData', JSON.stringify(appContextData));
    } else {
        console.error(`Property ${property} does not exist on appContext.`);
    }
}

  return (
    <AppContext.Provider
      value={{ apiClient, apiScope, appContextData, setData }}
    >
      {children}
    </AppContext.Provider>
  )
}

export const useApiClient = () => {
  const context = useContext(AppContext)

  if (!context) {
    throw new Error('useApiClient must be used within an AppProvider')
  }

  return context.apiClient
}
