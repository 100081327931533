import { useEffect, useState } from 'react'
import { useMsal } from '@azure/msal-react'

const useUser = () => {
  const { instance, accounts, inProgress } = useMsal()

  const [name, setName] = useState('')
  const [username, setUsername] = useState('')

  useEffect(() => {
    if (Array.isArray(accounts) && accounts.length !== 0) {
      setName(String(accounts[0].name))
      setUsername(String(accounts[0].username))
    }
  }, [inProgress, accounts, instance])

  return {
    inProgress,
    name,
    username
  }
}

export default useUser
