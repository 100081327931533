import { useApiClient } from '../context/AppContext'
import IResourceLoader from '../types/IResourceLoader'
import { useCallbackRef, useResourceLoader } from '.'
import { ILoadStoresList } from '../types/ILoadStoresList'
import { FetchStoresList } from '../types/FetchStoresList'
import storesApi from '../api/storesApi'
import { IDetails } from '../types/IStoreDetails'

const useSearchStoresListLoader = (): [
  IResourceLoader<IDetails[]>,
  ILoadStoresList
] => {
  const apiClient = useApiClient()

  const fetchStores = async (code?: string, ...params: any) => {
    if (code !== undefined && code !== '') {
      const store = await storesApi.getStore(apiClient, code!).then(r => r)
      return [store]
    } else if (params?.length > 0 && params[0] !== '') {
      const stores = await storesApi
        .getStores(apiClient, params?.[0], params?.[1], params?.[2])
        .then(r => r)
      return stores
    }
    return []
  }

  const resource = useResourceLoader<IDetails[]>('Unable to load stores list')

  const loader = useCallbackRef<FetchStoresList>(
    (code?: string, ...params: any) =>
      resource.load(async () => fetchStores(code, ...params))
  )

  return [resource, loader]
}

export default useSearchStoresListLoader
