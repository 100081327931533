import { CheckboxField } from '@jsluna/form'
import { GridItem, GridWrapper, Container } from '@jsluna/grid'
import { Button } from '@jsluna/button'
import { Modal, ModalHeading } from '@jsluna/modal'
import { PropTypes } from 'prop-types'
import { applyFilter, resetFilter } from '../../../utils/filtering'

const FilterPanel = ({
  devices,
  changeFilteredDevices,
  handleModal,
  isModalOpen,
  checkedItems,
  setCheckedItems,
  changeFilteredPills,
  setIsSorted,
  handleNoFilterState,
}) => {
  const uniqueDevices = devices.filter(
    (obj, pos, arr) =>
      arr
        .map(device => device.deviceInfo.simpleModel)
        .indexOf(obj.deviceInfo.simpleModel) === pos,
  )

  const valueIsClicked = value => !!checkedItems.includes(value)

  const handleToggle = data => {
    const isChecked = checkedItems.some(checkedItem => checkedItem === data)
    if (isChecked) {
      setCheckedItems(checkedItems.filter(checkedItem => checkedItem !== data))
    } else {
      setCheckedItems(checkedItems.concat(data))
    }
  }

  function handleApplyFilters() {
    const filteredData = applyFilter(devices, checkedItems, handleNoFilterState)
    changeFilteredDevices(filteredData)
    changeFilteredPills(checkedItems)
  }

  return (
    <div className="dm__filter-panel">
      {isModalOpen && (
        <Container className="filter-modal">
          <Modal
            fullScreen
            open={isModalOpen}
            handleClose={() => handleModal()}
          >
            <ModalHeading element="h2">Filter by handset</ModalHeading>
            <div>
              {devices.length > 0 ? (
                <>
                  <CheckboxField
                    label="Device Type"
                    name="checkbox-device-type"
                    options={uniqueDevices.map(device => ({
                      label: device.deviceInfo.simpleModel,
                      value: device.deviceInfo.simpleModel,
                      checked: valueIsClicked(device.deviceInfo.simpleModel),
                      onChange: () => {
                        handleToggle(device.deviceInfo.simpleModel)
                      },
                    }))}
                  />
                  <CheckboxField
                    label="Filter by status"
                    name="checkbox-device-status"
                    options={[
                      {
                        value: 'true',
                        label: 'Online (checked in today)',
                        checked: valueIsClicked('true'),
                        onChange: () => {
                          handleToggle('true')
                        },
                      },
                      {
                        value: 'false',
                        label: 'Offline (not checked in)',
                        checked: valueIsClicked('false'),
                        onChange: () => {
                          handleToggle('false')
                        },
                      },
                    ]}
                  />
                </>
              ) : null}
              <Button
                variant="filled"
                fullWidth
                onClick={() => {
                  handleApplyFilters()
                  handleModal()
                }}
              >
                Apply
              </Button>
              <Button
                className="ln-u-margin-ends*2"
                variant="text"
                fullWidth
                onClick={() => {
                  resetFilter(
                    setCheckedItems,
                    changeFilteredDevices,
                    setIsSorted,
                    handleNoFilterState,
                  )
                  handleModal()
                }}
              >
                Reset
              </Button>
            </div>
          </Modal>
        </Container>
      )}

      {devices.length > 0 ? (
        <>
          <GridWrapper className="ln-u-bg-color-white ln-u-soft-sides dm__filterPanel">
            <GridItem size="1/1" className="ln-u-push-top">
              <CheckboxField
                className="checkbox-device-type"
                label="Device Type"
                name="checkbox-device-type"
                options={uniqueDevices.map(device => ({
                  label: device.deviceInfo.simpleModel,
                  value: device.deviceInfo.simpleModel,
                  checked: valueIsClicked(device.deviceInfo.simpleModel),
                  onChange: () => {
                    handleToggle(device.deviceInfo.simpleModel)
                  },
                }))}
              />
              <CheckboxField
                label="Filter by status"
                name="checkbox-device-status"
                options={[
                  {
                    value: 'true',
                    label: 'Online (checked in today)',
                    checked: valueIsClicked('true'),
                    onChange: () => {
                      handleToggle('true')
                    },
                  },
                  {
                    value: 'false',
                    label: 'Offline (not checked in)',
                    checked: valueIsClicked('false'),
                    onChange: () => {
                      handleToggle('false')
                    },
                  },
                ]}
              />
              <Button
                variant="filled"
                fullWidth
                onClick={() => handleApplyFilters()}
              >
                Apply
              </Button>
              <Button
                className="ln-u-margin-ends*2"
                variant="text"
                fullWidth
                onClick={() =>
                  resetFilter(
                    setCheckedItems,
                    changeFilteredDevices,
                    setIsSorted,
                    handleNoFilterState,
                  )
                }
              >
                Reset
              </Button>
            </GridItem>
          </GridWrapper>
        </>
      ) : null}
    </div>
  )
}

FilterPanel.propTypes = {
  devices: PropTypes.instanceOf(Array),
  isModalOpen: PropTypes.bool.isRequired,
  handleModal: PropTypes.func,
  checkedItems: PropTypes.instanceOf(Array),
  setCheckedItems: PropTypes.func,
  changeFilteredPills: PropTypes.func,
  changeFilteredDevices: PropTypes.func,
  setIsSorted: PropTypes.func,
  handleNoFilterState: PropTypes.func,
}

FilterPanel.defaultProps = {
  devices: [],
  handleModal: () => {},
  checkedItems: [],
  setCheckedItems: () => {},
  changeFilteredPills: () => {},
  changeFilteredDevices: () => {},
  setIsSorted: () => {},
  handleNoFilterState: () => {},
}

export default FilterPanel
