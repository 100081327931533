import { PropTypes } from 'prop-types'
import DevicesTableRow from './DevicesTableRow'
import DevicesTableHead from './DevicesTableHead'

const DevicesTable = ({
  devices,
  filteredDevices,
  handleSort,
  isSorted,
  deviceData,
}) => {
  const sortFilteredDevices = () => {
    if (isSorted) {
      return deviceData.map(filteredDevice => (
        <DevicesTableRow
          className="dm__device-table-row"
          device={filteredDevice}
          key={filteredDevice.deviceInfo.serialNumber}
        />
      ))
    }

    return filteredDevices.map(filteredDevice => (
      <DevicesTableRow
        className="dm__device-table-row"
        device={filteredDevice}
        key={filteredDevice.deviceInfo.serialNumber}
      />
    ))
  }

  const sortDevices = () => {
    if (isSorted) {
      return deviceData.map(eachDevice => (
        <DevicesTableRow
          className="dm__device-table-row"
          device={eachDevice}
          key={eachDevice.deviceInfo.serialNumber}
        />
      ))
    }
    return devices.map(device => (
      <DevicesTableRow
        className="dm__device-table-row"
        device={device}
        key={device.deviceInfo.serialNumber}
      />
    ))
  }

  return (
    <>
      <DevicesTableHead handleSort={handleSort} />
      {filteredDevices.length > 0 ? (
        <tbody className="ln-c-table__body">{sortFilteredDevices()}</tbody>
      ) : (
        <tbody className="ln-c-table__body">{sortDevices()}</tbody>
      )}
    </>
  )
}

DevicesTable.propTypes = {
  devices: PropTypes.instanceOf(Array),
  filteredDevices: PropTypes.instanceOf(Array),
  handleSort: PropTypes.func,
  isSorted: PropTypes.bool.isRequired,
  deviceData: PropTypes.instanceOf(Array),
}

DevicesTable.defaultProps = {
  devices: [],
  filteredDevices: [],
  handleSort: () => {},
  deviceData: [],
}

export default DevicesTable
